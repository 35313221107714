<template>
    <div class="app-container">
        <el-form ref="form" :model="form" label-width="80px">
            <el-row>
                <el-col :span="8">
                    <el-form-item label="所属公司">
                        <el-select v-model="form.companyId" clearable>
                            <el-option v-for="item in companys" :key="item.id" :value="item.id"
                                       :label="item.name"></el-option>
                        </el-select>
                    </el-form-item>
                </el-col>
                <el-col :span="8">
                    <el-form-item label="关键字">
                        <el-input v-model="form.keywords" placeholder="请输入姓名/手机号码" clearable style="width: 200px;"></el-input>
                    </el-form-item>
                </el-col>
                <el-col :span="8">
                    <el-button type="primary" @click="getUserList">查询</el-button>
                    <el-button @click="reset">重置</el-button>
                </el-col>
            </el-row>
            <div style="margin-bottom: 20px;">
                <el-button type="primary" @click="handleAdd" icon="el-icon-plus">新增账号</el-button>
                <el-button @click="handleExport">导出列表</el-button>
            </div>
            <el-table :data="users" border style="width: 100%">
                <el-table-column prop="realName" align="center" label="姓名" width="150px"></el-table-column>
                <el-table-column prop="mobile" align="center" label="手机号码" width="150px"></el-table-column>
                <el-table-column prop="loginPwd" align="center" label="密码" width="150px"></el-table-column>
                <el-table-column prop="companyId" align="center" label="所属公司" width="150px"
                                 :formatter="companyFormatter"></el-table-column>
                <el-table-column prop="status" align="center" label="账号状态" width="150px">
                    <template slot-scope="scope">{{scope.row.status === 1 ? '正常' : '冻结'}}</template>
                </el-table-column>
                <el-table-column prop="key4" align="center" label="操作" fixed="right" width="250px">
                    <template slot-scope="scope">
                        <el-button type="primary" @click="handleEdit(scope.row)">修改</el-button>
                        <el-button type="primary" v-if="scope.row.status === 1" @click="handleFreeze(scope.row)">冻结
                        </el-button>
                        <el-button type="success" v-else @click="handleFreeze(scope.row)">解冻</el-button>
                        <el-button type="danger" @click="handleDelete(scope.row)">删除</el-button>
                    </template>
                </el-table-column>
            </el-table>
        </el-form>
        <!-- 新增账号 -->
        <el-dialog title="新增账号" :visible.sync="addVisible" width="600px"
                   :close-on-click-modal="false">
            <el-form ref="addForm" :model="addForm" label-width="120px" :rules="addRules">
                <el-form-item label="姓名" prop="realName">
                    <el-input v-model="addForm.realName" placeholder="请输入姓名" style="width: 300px;"></el-input>
                </el-form-item>
                <el-form-item label="所属公司" prop="companyId">
                    <el-select v-model="addForm.companyId" placeholder="请选择所属公司" clearable style="width: 300px;">
                        <el-option v-for="item in companys" :key="item.id" :value="item.id"
                                   :label="item.name"></el-option>
                    </el-select>
                </el-form-item>
                <el-form-item label="手机号" prop="mobile">
                    <el-input v-model="addForm.mobile" placeholder="请输入手机号" type="tel" style="width: 300px;"></el-input>
                </el-form-item>
                <el-form-item label="密码" prop="loginPwd">
                    <el-input v-model="addForm.loginPwd" placeholder="请输入密码" type="password"
                              style="width: 300px;"></el-input>
                </el-form-item>
                <el-form-item label="确认密码" prop="confirmPwd">
                    <el-input v-model="addForm.confirmPwd" placeholder="请输入确认密码" type="password" style="width: 300px;"></el-input>
                </el-form-item>
            </el-form>
            <div slot="footer" class="dialog-footer">
                <el-button @click="addVisible = false">取 消</el-button>
                <el-button type="primary" @click="handleAddSubmit">确 定</el-button>
            </div>
        </el-dialog>
        <!-- 修改账号 -->
        <el-dialog title="修改账号" :visible.sync="editVisible" width="600px" :before-close="handleEditClose" :close-on-click-modal="false">
            <el-form ref="editForm" :model="editForm" label-width="120px" :rules="editRules">
                <el-form-item label="姓名" prop="realName">
                    <el-input v-model="editForm.realName" placeholder="请输入姓名" style="width: 300px;"></el-input>
                </el-form-item>
                <el-form-item label="所属公司" prop="companyId">
                    <el-select v-model="editForm.companyId" placeholder="请选择所属公司" clearable style="width: 300px;">
                        <el-option v-for="item in companys" :key="item.id" :value="item.id"
                                   :label="item.name"></el-option>
                    </el-select>
                </el-form-item>
                <el-form-item label="手机号" prop="mobile">
                    <el-input v-model="editForm.mobile" placeholder="请输入手机号" type="tel" style="width: 300px;"></el-input>
                </el-form-item>
                <el-form-item label="密码" prop="loginPwd">
                    <el-input v-model="editForm.loginPwd" placeholder="请输入密码" type="password"
                              style="width: 300px;"></el-input>
                </el-form-item>
                <el-form-item label="确认密码" prop="confirmPwd">
                    <el-input v-model="editForm.confirmPwd" placeholder="请输入确认密码" type="password"
                              style="width: 300px;"></el-input>
                </el-form-item>
            </el-form>
            <div slot="footer" class="dialog-footer">
                <el-button @click="handleEditClose">取 消</el-button>
                <el-button type="primary" @click="handleEditSubmit">确 定</el-button>
            </div>
        </el-dialog>
    </div>
</template>

<script>
import {
  addSysUserApi,
  changeUserStatusApi,
  exportSysUserApi,
  getCompanyListApi,
  getPublicKeyApi,
  getSysUserListApi,
  modifyUserApi
} from '@/api'
import {JSEncrypt} from 'jsencrypt'

export default {
        name: 'account',
        data() {
            const validatePwd = (rule, value, callback) => {
                if (value === '') {
                    callback(new Error('请再次输入密码'))
                } else if (value !== this.addForm.loginPwd) {
                    callback(new Error('两次输入密码不一致!'))
                } else {
                    callback()
                }
            }

            const validateMobile = (rule, value, callback) => {
              let reg = /^1(3|4|5|6|7|8|9)\d{9}$/
              if (!reg.test(value)) {
                callback(new Error('联系电话格式不正确'));
                return
              }
              callback();
            }
            const validatePwd2 = (rule, value, callback) => {
                if (value === '') {
                    callback(new Error('请再次输入密码'))
                } else if (value !== this.editForm.loginPwd) {
                    callback(new Error('两次输入密码不一致!'))
                } else {
                    callback()
                }
            }
            return {
                publicKey: undefined,
                form: {
                    companyId: undefined,
                    keywords: undefined
                },
                companys: [],
                users: [],
                addVisible: false,
                addForm: {
                    realName: undefined,
                    companyId: undefined,
                    mobile: undefined,
                    loginPwd: undefined,
                    confirmPwd: undefined
                },
                addRules: {
                    realName: [
                        {required: true, message: '请输入姓名', trigger: 'blur'}
                    ],
                    companyId: [
                        {required: true, message: '请选择所属公司', trigger: 'blur'}
                    ],
                    mobile: [
                        {required: true, message: '请输入手机号', trigger: 'blur'},
                      { validator: validateMobile, trigger: 'blur' }
                    ],
                    loginPwd: [
                        {required: true, message: '请输入密码', trigger: 'blur'}
                    ],
                    confirmPwd: [
                        {required: true, message: '请输入密码', trigger: 'blur'},
                        { validator: validatePwd, trigger: 'blur' }
                    ]
                },
                editRules: {
                    realName: [
                        {required: true, message: '请输入姓名', trigger: 'blur'}
                    ],
                    companyId: [
                        {required: true, message: '请选择所属公司', trigger: 'blur'}
                    ],
                    mobile: [
                        {required: true, message: '请输入手机号', trigger: 'blur'}
                    ],
                    loginPwd: [
                        {required: true, message: '请输入密码', trigger: 'blur'}
                    ],
                    confirmPwd: [
                        {required: true, message: '请输入密码', trigger: 'blur'},
                        { validator: validatePwd2, trigger: 'blur' }
                    ]
                },
                editForm: {
                    id: undefined,
                    realName: undefined,
                    companyId: undefined,
                    mobile: undefined,
                    loginPwd: undefined,
                    confirmPwd: undefined
                },
                editVisible: false
            }
        },
        mounted() {
            this.getCompanyList()
            this.getUserList()
            this.getPublicKey()
        },
        methods: {
            getPublicKey() {
                getPublicKeyApi().then(res => {
                    if (res.data) {
                        this.publicKey = res.data
                    }
                })
            },
            // 加密
            encryptedData(data) {
                if (!this.publicKey) {
                    return data
                }
                // 新建JSEncrypt对象
                const encryptor = new JSEncrypt()
                // 设置公钥
                encryptor.setPublicKey(this.publicKey)
                // 加密数据
                return encryptor.encrypt(data)
            },
            reset() {
                this.form.companyId = ''
                this.form.keywords = ''
                this.getUserList()
            },
            getCompanyList() {
                getCompanyListApi().then(res => {
                    if (res) {
                        this.companys = res.data
                    }
                })
            },
            getUserList() {
                getSysUserListApi(this.form).then(res => {
                    if (res) {
                        this.users = res.data
                    }
                })
            },
            handleFreeze(row) {
                this.$confirm('确认' + (row.status === 1 ? '冻结?' : '解冻?'), '提示', {
                    confirmButtonText: '确定',
                    cancelButtonText: '取消',
                    type: 'warning'
                }).then(() => {
                    changeUserStatusApi({
                        id: row.id,
                        status: row.status === 1 ? 2 : 1
                    }).then(res => {
                        if (res) {
                            this.$message.success('操作成功!')
                            this.getUserList()
                        }
                    })
                })
            },
            handleDelete(row) {
                this.$confirm('确认删除?', '提示', {
                    confirmButtonText: '确定',
                    cancelButtonText: '取消',
                    type: 'warning'
                }).then(() => {
                    changeUserStatusApi({
                        id: row.id,
                        status: 3
                    }).then(res => {
                        if (res) {
                            this.$message.success('删除成功!')
                            this.getUserList()
                        }
                    })
                })
            },
            handleAdd() {
                this.addVisible = true
                this.$refs['addForm'].resetFields()
            },
            handleAddSubmit() {
                this.$refs['addForm'].validate(valid => {
                    if (valid) {
                        const data = JSON.parse(JSON.stringify(this.addForm))
                        data['loginPwd'] = this.encryptedData(data['loginPwd'])
                        addSysUserApi(data).then(res => {
                            if (res) {
                                this.$message.success('添加成功!')
                                this.getUserList()
                                this.addVisible = false
                            }
                        })
                    }
                })
            },
            handleEdit(row) {
                this.editForm['id'] = row.id
                this.editForm['realName'] = row.realName
                this.editForm['companyId'] = row.companyId
                this.editForm['mobile'] = row.mobile
                this.editForm['loginPwd'] = row.loginPwd
                this.editVisible = true
            },
            handleEditClose() {
                this.$refs['editForm'].resetFields()
                this.editVisible = false
            },
            handleEditSubmit() {
                this.$refs['editForm'].validate(valid => {
                    if (valid) {
                        const data = JSON.parse(JSON.stringify(this.editForm))
                        data['loginPwd'] = this.encryptedData(data['loginPwd'])
                        modifyUserApi(data).then(res => {
                            if (res) {
                                this.$message.success('修改成功!')
                                this.getUserList()
                                this.editVisible = false
                            }
                        })
                    }
                })
            },
            handleExport() {
                exportSysUserApi({
                    companyId: this.form.companyId,
                    keywords: this.form.keywords
                }).then(res => {
                    if (res) {
                        const blob = new Blob([res], {type: 'application/vnd.ms-excel'})
                        let link = document.createElement('a')
                        link.href = window.URL.createObjectURL(blob)
                        link.download = '系统账号数据导出'
                        link.click()
                    }
                })
            },
            companyFormatter(row) {
                return this.companys.filter(item  => item.id === row.companyId)[0]['name']
            }
        }
    }
</script>

<style scoped>

</style>
